var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"kiosks-items-table"},[_c('el-table',{staticClass:"table-responsive table-flush table-bordered",attrs:{"header-row-class-name":"thead-light","data":_vm.devices}},[_c('el-table-column',{attrs:{"label":_vm.translate('NAME'),"prop":"attributes.label","min-width":"220px"}}),_c('el-table-column',{attrs:{"label":_vm.translate('Location'),"min-width":"220px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticStyle:{"width":"12px","margin-right":"10px"},attrs:{"src":require(("@/assets/images/" + (row.attributes.status) + ".png"))}}),_vm._v(" "+_vm._s(row.attributes['location-name'])+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('OS Version'),"min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.attributes['os-version']))]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Payment Device'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.attributes['ip-address'])+" - "+_vm._s(row.attributes['terminal-id']))]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Last Seen'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.lastSeenAt(row)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Alarm Subject'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attributes['alarm'] !== null)?_c('span',[_vm._v(_vm._s(row.attributes['alarm'].subject))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Alarm Reason'),"min-width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attributes['alarm'] !== null)?_c('span',[_vm._v(_vm._s(row.attributes['alarm'].reason))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Alarm Information'),"min-width":"480px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attributes['alarm'] !== null)?_c('button',{staticClass:"btn btn-twitter icon-button mt-3",staticStyle:{"border-radius":"6px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.openMoreDetails(row.id)}}},[_c('p',{staticClass:"text-more"},[_vm._v(_vm._s(_vm.translate('More Details')))]),_c('span',{staticClass:"icon-button__badge"},[_vm._v("1")])]):_vm._e(),(_vm.moreDetails && (_vm.IdHolder == row.id))?_c('div',{staticStyle:{"overflow-x":"auto"}},[(row.attributes['alarm'] !== null)?_c('table',[_c('tr',[_c('th',{staticStyle:{"color":"#8898AA","font-size":"10px"}},[_vm._v(_vm._s(_vm.translate('ALARM INFORMATION')))]),_c('th',{staticStyle:{"color":"#8898AA","font-size":"10px"}},[_vm._v(_vm._s(_vm.translate('Date & Time')))]),_c('th',{staticStyle:{"color":"#8898AA","font-size":"10px"}},[_vm._v(_vm._s(_vm.translate('ACTION')))])]),_c('tr',[_c('td',[_c('button',{staticClass:"btn btn-danger info-btn",attrs:{"type":"button"}},[_c('p',{staticClass:"btn-inner-1 "},[_vm._v(" "+_vm._s(row.attributes['alarm'].reason)+" ")])])]),_c('td',[_vm._v(_vm._s(_vm.formatDate(row.attributes['created-at'])))]),_c('td',[_c('base-button',{staticClass:"btn btn-primary clr-btn",on:{"click":function($event){return _vm.$emit('reset-alarm', row)}}},[_c('p',{staticClass:"btn-inner"},[_vm._v(_vm._s(_vm.translate('Clear')))])])],1)])]):_vm._e()]):_vm._e(),(row.attributes['alarm'] === null)?_c('img',{staticStyle:{"width":"12px","margin-right":"10px"},attrs:{"src":require("@/assets/images/online.png")}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('ACTION'),"min-width":"220px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button-group',{staticClass:"mt-3"},[_c('el-tooltip',{attrs:{"content":_vm.translate('Edit Device'),"placement":"top"}},[_c('base-button',{staticClass:"btn btn-sm btn-twitter btn-second btn-icon-only rounded-circle",on:{"click":function($event){return _vm.$emit('edit-clicked', row)}}},[_c('i',{staticClass:"far fa-edit"})])],1),(row.attributes['is-enrolled'])?_c('base-button',{staticClass:"btn btn-sm btn-success btn-icon-only rounded-circle",on:{"click":function($event){return _vm.$emit('refresh-mdm-clicked', row)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-sync-alt"})])]):_vm._e()],1),(row.id)?_c('el-tooltip',{attrs:{"content":_vm.translate('View Device'),"placement":"top"}},[_c('router-link',{attrs:{"to":{ name: 'Kiosk_details', params: { id: row.id }  }}},[_c('base-button',{staticClass:"text-white btn btn-sm btn-twitter btn-icon-only rounded-circle mt-3 ml-1"},[_c('i',{staticClass:"far fa-solid fa-eye"})])],1)],1):_vm._e(),_c('el-tooltip',{attrs:{"content":_vm.translate('Deactivate'),"placement":"top"}},[_c('base-button',{staticClass:"text-white btn btn-sm btn-twitter btn-icon-only rounded-circle mt-3 ml-1",on:{"click":function($event){return _vm.$emit('deactivate-kiosk', row)}}},[_c('i',{staticClass:"far fa-solid fa-power-off"})])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }